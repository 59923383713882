import { t } from "i18n:astro";
import { useCallback } from "react";
import ExpandableSection from "../../../components/layout/ExpandableSection/ExpandableSection";
import { ExpandableSectionSize } from "../../../components/layout/ExpandableSection/types";
import { ExpandableId } from "../../../data/expandable";
import { ElementTheme } from "../../../types/theme";

const expandableConfig = {
  size: ExpandableSectionSize.STANDARD,
  theme: ElementTheme.LIGHT,
};

const Invest = () => {
  const renderList = useCallback(
    (vals: string[]) => (
      <ul>
        {vals.map((v, i) => (
          <li key={i}>
            <span>{v}</span>
          </li>
        ))}
      </ul>
    ),
    [t],
  );

  return (
    <div>
      <ExpandableSection
        id={ExpandableId.INVEST_1}
        title={t("home:invest.expandable.investment-strategies.title")}
        {...expandableConfig}
      >
        {renderList([
          t("home:invest.expandable.investment-strategies.content.0"),
          t("home:invest.expandable.investment-strategies.content.1"),
          t("home:invest.expandable.investment-strategies.content.2"),
          t("home:invest.expandable.investment-strategies.content.3"),
        ])}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.INVEST_2}
        title={t("home:invest.expandable.target-sectors.title")}
        {...expandableConfig}
      >
        {renderList([
          t("home:invest.expandable.target-sectors.content.0"),
          t("home:invest.expandable.target-sectors.content.1"),
          t("home:invest.expandable.target-sectors.content.2"),
          t("home:invest.expandable.target-sectors.content.3"),
          t("home:invest.expandable.target-sectors.content.4"),
          t("home:invest.expandable.target-sectors.content.5"),
          t("home:invest.expandable.target-sectors.content.6"),
        ])}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.INVEST_3}
        title={t("home:invest.expandable.core-geographies.title")}
        {...expandableConfig}
      >
        {renderList([
          t("home:invest.expandable.core-geographies.content.0"),
          t("home:invest.expandable.core-geographies.content.1"),
        ])}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.INVEST_4}
        title={t("home:invest.expandable.capital-strategy.title")}
        {...expandableConfig}
      >
        {renderList([
          t("home:invest.expandable.capital-strategy.content.0"),
          t("home:invest.expandable.capital-strategy.content.1"),
        ])}
      </ExpandableSection>
      <ExpandableSection
        id={ExpandableId.INVEST_5}
        title={t("home:invest.expandable.sourcing.title")}
        {...expandableConfig}
      >
        {renderList([
          t("home:invest.expandable.sourcing.content.0"),
          t("home:invest.expandable.sourcing.content.1"),
          t("home:invest.expandable.sourcing.content.2"),
          t("home:invest.expandable.sourcing.content.3"),
        ])}
      </ExpandableSection>
    </div>
  );
};
export default Invest;
